import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';

function PasswordRecovery() {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const emailChange = (e) => {
        setUsername(e.target.value);
        setError('');
        setMessage('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let data = {
            username: username,
        };

        try {
            let req = await fetch('https://' + process.env.REACT_APP_API_HOST + '/api/auth/recover', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
            });

            if (!req.ok) {
                throw new Error(`Recovery error: status ${req.status}`);
            }

            let response = await req.json();
            if (response.msg === 'ok') {
                setMessage('Recovery email sent successfully.');
            } else {
                setError(response.msg);
            }

        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    return (
        <Row>
            <Col xs={8} className="mx-auto">
                <div className="login-reg-box">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="" placeholder="Enter your username" onChange={emailChange} />
                        </Form.Group>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {message && <Alert variant="success">{message}</Alert>}
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
            </Col>
        </Row>
    );
}

export default PasswordRecovery;
