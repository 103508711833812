import { useId, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { OtpSetup } from './2Fa';
import { ShowCurrrentBalance, AddFunds } from './Balance';
import { verifyPassword } from './Utils';


function ChangePassword() {

	const [passwordError, setPasswordError] = useState( false );
	const [passwordMatchError, setPasswordMatchError] = useState( false );

	let passwordChange = ( e ) => {
		let r = verifyPassword( e.target.value );
		( r.error.length ) ? setPasswordError( r.error.join(' ') ) : setPasswordError( false );
	}

	let passwordChange2 = ( e ) => {
		let p1 = document.getElementById('pwChange.1').value;
		let p2 = document.getElementById('pwChange.2').value;
		( p1 != p2  ) ? setPasswordMatchError( true ) : setPasswordMatchError( false );
	}

	let changePasswordSubmit = ( e ) => {
		e.preventDefault()

		if( passwordError || passwordMatchError ) return

		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/user/changepassword', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify({
        password: document.getElementById('pwChange.1').value
      })
		}).then(response => {
        return response.json()
    }).then(info => {

		})
	}

	return(
		<>
			<Row>
				<h4>Change Pasword</h4>
			</Row>
			<Row>
				<Col xs={4} className="mx-auto">
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        			<Form.Label>Current Password</Form.Label>
        			<Form.Control type="" placeholder="" />
      			</Form.Group>
						<Form.Group className="mb-3" controlId="pwChange.1">
            	<Form.Label>New Password</Form.Label>
            	<Form.Control type="" placeholder="" onChange={ passwordChange } />
							{ passwordError &&
								<Alert variant={'danger'}>
									{passwordError}
								</Alert>
							}
          	</Form.Group>
						<Form.Group className="mb-3" controlId="pwChange.2">
            	<Form.Label>New Password Again</Form.Label>
            	<Form.Control type="" placeholder="" onChange={ passwordChange2 } />
							{ passwordMatchError &&
                <Alert variant={'danger'}>
                  Passwords do not match.
                </Alert>
              }
          	</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            	<Button variant="primary" onClick={ changePasswordSubmit } >Save</Button>
          	</Form.Group>
					</Form>
				</Col>
			</Row>
		</>
	)
}

function Settings() {

	//const [showOtpVerify, setShowOtpVerify] = useState( false );
	const [verifyTrigger, setVerifyTrigger] = useState( false );

	let clickVerifyBtn = () => {
		setVerifyTrigger( true );
	}

  return (
		<Col>
			<h3>Settings</h3>
			<Row className="py-1">
					<Col xs={6} className="d-flex align-items-center">
							To enable 2FA, scan the QRcode to the right and click Verify.
					</Col>
					<Col xs={2} className="d-flex align-items-center">
						<Button variant="primary" onClick={clickVerifyBtn} >Verify</Button>
					</Col>
					<Col xs={4}>
						<OtpSetup
							verifyTrigger={verifyTrigger}
							setVerifyTrigger={setVerifyTrigger}
						/>
					</Col>
			</Row>

			<ChangePassword />

			<AddFunds />

		</Col>
  );
}

export default Settings;
