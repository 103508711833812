
exports.verifyPassword = ( password ) => {

	let r = {
		status: false,
		error: []
	}

	if( password.length < 14 ) {
		r.error.push('Password must be 14 characters or more.')
	}

	if( !/[0-9]/.test( password ) ) {
		r.error.push('Password must contain at least one number.')
	}

	if( !( /[A-Z]/.test( password ) && /[a-z]/.test( password ) ) ) {
    r.error.push('Password must contain both upper and lower case.')
  }

	if( !/[!@#\$\%\^\&\*\(\)\'\?\.\,]/.test( password ) ) {
    r.error.push('Password must contain at least one special character. ( !@#$%^&*()\'?., )')
  }

	return r
}
